<style lang="less" scoped>
.result {
  height: 100vh;
  overflow: hidden;
  background: url('../../assets/img/bg.png') no-repeat center;
  background-size: cover;
  .header {
    height: 100px;
    position: relative;
    &-title {
      font-size: 40px;
      font-weight: 500;
    }
    &-back {
      position: absolute;
      left: 40px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .content {
    padding: 100px 0;
  }
  .circle {
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background-color: #e9e9e9;
    border: 30px solid #4380f7;
    margin: 0 auto;
    p {
      font-size: 40px;
      margin-bottom: 40px;
    }
    span {
      font-size: 68px;
      font-weight: bold;
      color: #0053f4;
    }
  }
  .tetx {
    font-size: 32px;
    font-weight: 500;
    line-height: 50px;
  }
  .error {
    font-size: 30px;
    font-weight: 500;
    color: red;
    text-align: center;
    padding: 0 20px;
  }
}
</style>
<template>
  <div class="result flex flex-culomn">
    <div class="header flex-center">
      <div class="header-title">预审批额度</div>
      <div class="header-back" @click="goBack">返回</div>
    </div>
    <div class="content flex-1 flex-culomn flex-center-around">
      <div class="circle flex-center flex-culomn">
        <p>您的信用卡额度预估</p>
        <span>¥{{ num }}.00</span>
      </div>

      <div class="tetx flex-center flex-culomn">
        <p>以上额度仅供参考，以实际审批结果为准，</p>
        <p>银行有权随时调整该额度</p>
      </div>

      <div class="error">
        !疑存在不规范用卡行为，请规范用卡，优化账单
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'result',
  components: {},
  data() {
    return {
      num: 0,
      arry: [33000, 49000, 52000, 67000, 96000],
      timer: null
    }
  },
  created() {
    this.setResult()
  },
  methods: {
    setResult() {
      let key = Math.floor(Math.random() * 5)
      let result = this.arry[key]
      let time = parseFloat(10000/result).toFixed(1)
      this.count(result, time)
    },

    count(result, time) {
      let _this = this
      console.log('result', result)
      this.timer = setInterval(()=> {
        if (_this.num < result) {
          _this.num += 250
        } else {
          clearInterval(_this.timer)
        }
      }, time)
    },

    goBack() {
      this.$jumpTo('/home')
    }
  }
}
</script>
